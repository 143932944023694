import Uri from 'goog:goog.Uri';
import KeyCodes from 'goog:goog.events.KeyCodes';
import XhrIo from 'goog:goog.net.XhrIo';

import {OauthButtons} from '/src/com/yext/platform/users/js/common/oauthbuttons';
import {openCookieDrawer} from '/src/com/yext/storm/js/osano/osano';
import {Button} from '/ui/components/button/button';
import {Checkbox} from '/ui/components/checkbox/checkbox';
import {Input} from '/ui/components/input/input';
import {slowRegisterComponentForJsonData} from '/ui/lib/easyreact';
import {logError} from '/ui/lib/errors';


import SsoIcon from '/src/com/yext/platform/users/public/images/sso_icon.svg';

const QueryData = goog.require('goog.Uri.QueryData');

export function PartitionLogin(props) {
  /**
   * State Diagram:
   *
   * isSso:           False  ------------------ clicks SSO button --------------->  True
   * isPassword:      False  -------- inputted username is password login --------> True
   * isNonUniqueSaml: False  -------- username is shared by multiple users -------> True
   * isIdp:           False  ------ username corresponds to an IDP only user -----> True
   * initiateSso:     False  - username corresponds to an SP only or SP+IDP user -> True
   */
  const [username, setUsername] = React.useState(props.username);
  const [password, setPassword] = React.useState('');
  const [accountId, setAccountId] = React.useState(props.b);

  // True when the user clicks the SSO button on the initial login screen
  const [isSso, setIsSso] = React.useState(false);
  // When password login is confirmed for the user trying to log in
  const [isPassword, setIsPassword] = React.useState(false);
  // When the inputted username is shared by multiple users
  const [isNonUniqueSaml, setIsNonUniqueSaml] = React.useState(false);
  // When the user trying to log in is confirmed IDP SSO login type
  const [isIdp, setIsIdp] = React.useState(false);
  // When the user is confirmed to be a SP or SP+IDP user, initiate SSO flow via SP
  const [initiateSso, setInitiateSso] = React.useState(false);
  // Error message to be displayed
  const [error, setError] = React.useState(props.errorMessage);
  // List of login types associated with a given username
  const [loginTypes, setLoginTypes] = React.useState([]);
  // Whether to remember the username for future login attempts.
  const [rememberMe, setRememberMe] = React.useState(true);
  // Whether to disable the submit button of the login form
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = React.useState(false);

  const submitForm = () => document.getElementById('users-login-form').submit();
  /** Whenever we change initiateSso to true, submit the form */
  React.useEffect(() => {
    if (initiateSso) {
      submitForm();
    }
  }, [initiateSso]);

  const firstLoad = React.useRef(true);
  React.useEffect(() => {
    if (!firstLoad.current) {
      setError('');
    }
    firstLoad.current = false;
  }, [isSso, isPassword, isNonUniqueSaml, isIdp]);

  React.useEffect(() => {
    if ((props['global'] || props['reauth']) && username && !isPassword && !props['passwordOnly'] && !isNonUniqueSaml) {
      // If it is a redirect from global login, and there is a username included,
      // and it is at the username submission stage of the login flow, submit username
      firstLoad.current = true;
      getUserTypeByUsername();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Make API call to get a list of login types for a given username. */
  const getUserTypeByUsername = () => {
    if (username === null || username.trim() === '') {
      setError(yext.msg('Please enter an email/username.'));
      return;
    }
    // deactivate the button during the wait time for the request
    setIsSubmitBtnDisabled(true);
    const uri = new Uri('/users/getLoginType');
    const queryData = Uri.QueryData.createFromMap({
      'username': username,
      'b': accountId,
    });
    if (props['redirectUrl']) {
      queryData.set('c', props['redirectUrl']);
    }
    uri.setQueryData(queryData);
    XhrIo.send(
      uri,
      e => {
        if (e.target.isSuccess()) {
          determineUserLoginType(e.target.getResponseJson());
        } else {
          setError(e.target.getResponseJson());
        }
        setIsSubmitBtnDisabled(false);
      },
      'GET',
      null,
      {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    );
  };

  /** Record login attempt (failure). */
  const logLoginAttempt = loginType => {
    const uri = new Uri('/users/logLoginAttempt');
    uri.setQueryData(Uri.QueryData.createFromMap({
      'username': username,
      'businessId': accountId,
      'loginType': loginType,
      'error': 'IDP_INITIATED_ERROR',
    }));
    XhrIo.send(
      uri,
      null,
      'POST',
      null,
      {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    );
  };

  React.useEffect(() => {
    if (isIdp) {
      // Log login attempts for IDP users.
      logLoginAttempt('IDP_ONLY');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdp]);

  React.useEffect(() => {
    /** Prevent enter from submitting form, unless desired when submitting password login */
    const handleKeyShortcuts = event => {
      if (event.keyCode === KeyCodes.ENTER) {
        event.preventDefault();
        if (isPassword || props['passwordOnly']) {
          // If we are on the password page, enter should submit the form
          submitForm();
        } else if (isNonUniqueSaml) {
          // If we are on the non-unique SAML page, enter should use business ID to determine login
          determineUserToLoginViaAccountId();
        } else {
          // If we are on the username page, enter should find the login type by username
          getUserTypeByUsername();
        }
      }
    };

    document.addEventListener('keydown', handleKeyShortcuts);
    return () => {
      document.removeEventListener('keydown', handleKeyShortcuts);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNonUniqueSaml, isPassword, getUserTypeByUsername, props]);

  /**
   * If a given list of login types are all for the same account, and at least
   * one of them is a password user, use the password login flow.
   *
   * @param {!Array<*>} loginTypes
   */
  const shouldUsePasswordLogin = loginTypes => {
    if (loginTypes.length === 0) {
      return false;
    }

    const businessId = loginTypes[0]['businessId'];
    let allLoginTypesInSameBusiness = true;
    let includesPasswordLoginType = false;
    for (const type of loginTypes) {
      if (businessId !== type['businessId']) {
        allLoginTypesInSameBusiness = false;
      }
      if (type['loginType'] === 'PASSWORD') {
        includesPasswordLoginType = true;
      }
    }

    return allLoginTypesInSameBusiness && includesPasswordLoginType;
  };

  /**
   * This method is used when there are multiple SSO users, then the user submits the account ID.
   * If the business ID matches a user with IDP initiated login. If so, we are done.
   * Else if the business ID matches a user with SP initiated login, we initiate SSO login:
   */
  const determineUserToLoginViaAccountId = () => {
    const loginTypesOfAccount =
        loginTypes.filter(
          type => type['businessId']
                && type['businessId'].toString() === accountId.toString());

    if (shouldUsePasswordLogin(loginTypesOfAccount)) {
      setIsNonUniqueSaml(false);
      setIsPassword(true);
      setIsSso(false);
      return;
    } else {
      for (const type of loginTypesOfAccount) {
        if (type['loginType'] === 'IDP_ONLY') {
          setIsIdp(true);
          setIsNonUniqueSaml(false);
          return;
        } else if (type['loginType'] === 'SP_ONLY' || type['loginType'] === 'IDP_AND_SP') {
          setInitiateSso(true);
          return;
        }
      }
    }
    setError('Invalid Login');
  };

  /**
   * Given a list of loginTypes, determine the next phase of the login flow.
   *
   * Note: (APV-573) We should never return 0 login types.
   *
   * If there is just one user, proceed with the login flow based on login type
   * Else if there are multiple users,
   *   if all of those users belong to the same account and at least one of
   *   them is a password user, proceed as if it were a password user.
   *   else, ask for account ID to determine the user.
   * @param {!Array<*>} loginTypes
   */
  const determineUserLoginType = loginTypes => {
    if (loginTypes.length === 1) {
      switch (loginTypes[0]['loginType']) {
        case 'IDP_ONLY':
          setAccountId(loginTypes[0]['businessId']);
          setIsIdp(true);
          setIsPassword(false);
          break;
        case 'SP_ONLY':
        case 'IDP_AND_SP':
          setAccountId(loginTypes[0]['businessId']);
          setInitiateSso(true);
          break;
        case 'PASSWORD':
          checkForWhitelabelRedirect(loginTypes[0]);
          break;
      }
    } else {
      if (shouldUsePasswordLogin(loginTypes)) {
        setIsPassword(true);
        setIsSso(false);
      } else {
        setIsNonUniqueSaml(true);
        setIsSso(false);
        setIsPassword(false);
        setLoginTypes(loginTypes);
      }
    }
  };

  // If there are instructions included in the user login info indicating that the user is on the
  // wrong whitelabeled domain, redirect them to the correct page before password submission.
  const checkForWhitelabelRedirect = userInfo => {
    if (userInfo['newLoginPage']) {
      window.location = userInfo['newLoginPage'];
    } else {
      setIsPassword(true);
      setIsSso(false);
    }
  };

  // This method is executed when the SSO button at the bottom of the page is pressed
  const clickSsoButton = () => {
    if (props['ssoLoginType'] === 'IDP_AND_SP' || props['ssoLoginType'] === 'SP_ONLY') {
      setInitiateSso(true);
    } else if (props['ssoLoginType'] === 'IDP_ONLY') {
      setIsIdp(true);
    } else {
      setIsSso(true);
    }
  };

  const getLoginFormButton = () => {
    if (isPassword || props['passwordOnly']) {
      // If we confirmed that the user is a password user, the button should submit login
      return <button
        id="submission-button"
        type="button"
        className="yext-login__button yext-login__button--primary yext-login__button--submit"
        onClick={submitForm}
      >
        <span>{props['reauth'] ? yext.msg('VERIFY') : yext.msg('SIGN IN')}</span>
      </button>;
    }

    if (isNonUniqueSaml) {
      // If we have multiple users, we want to select the user to log in on click
      return <button
        id="submission-button"
        type="button"
        className="yext-login__button yext-login__button--primary yext-login__button--submit"
        onClick={determineUserToLoginViaAccountId}
      >
        <span>{yext.msg('NEXT')}</span>
      </button>;
    }

    // Other cases, get the users associated with the inputted username
    return <button
      id="submission-button"
      type="button"
      className="yext-login__button yext-login__button--primary yext-login__button--submit"
      onClick={getUserTypeByUsername}
      disabled={isSubmitBtnDisabled}
    >
      <span>{yext.msg('NEXT')}</span>
    </button>;
  };

  let url = '/users/partition/login';
  // maintain the same redirectURL upon form submission in case the password is incorrect
  if (props['redirectUrl']) {
    url = url + '?c=' + encodeURIComponent(props['redirectUrl'] + window.location.hash);
  }

  const forgotPasswordSignupSection =
    props['isYextDomain'] && !props['isMobileRedirect']
      ? <div className="yext-login__forgot-or-signup">
        <a href="/users/forgotpassword">{yext.msg('Forgot password?')}</a>
        <span>
          {yext.msg('Looking for Hearsay? ')}
          <a href="https://login.hearsaysocial.com">{yext.msg('Sign In')}</a>
        </span>
      </div>
      : <a className="yext-login__forgot-only" href="/users/forgotpassword">
        {yext.msg('Forgot password?')}
      </a>;

  const rememberMeLabel =
    props['isMobileRedirect']
      ? yext.msg('Remember me (uncheck this if using a public or shared device)')
      : yext.msg('Remember me (uncheck this if using a public or shared computer)');

  return (
    <div>
      {props['reauth']
        && <div className="yext-login-reauth__text">
          {yext.msg('In order to update users, you will need to verify your identity again.')}
        </div>}
      <div className="users__login-form">
        <div className="js-ajax-error center-align"></div>
        {error && <span id="error">{error}</span>}
        <form method="POST" action={url} className="login-form" id="users-login-form">
          <input type="hidden" name="b" className="js-business-id" value={accountId} />
          <input type="hidden" className="js-redirect-url" value={props['redirectUrl'] + window.location.hash} />
          <input type="hidden" name="initiateSso" value={initiateSso} />
          <input type="hidden" name="rememberMe" value={rememberMe} />
          <input type="hidden" name="reauth" value={props['reauth']} />

          {!isNonUniqueSaml
            && <>
              <Input
                id="login-username"
                type="text"
                name="username"
                label={yext.msg('Username')}
                value={username}
                onChange={newUsername => setUsername(newUsername)}
                placeholder={yext.msg('Email / Username')}
                hideLabel={true}
                readOnly={(isPassword && !props['global']) || isIdp || props['reauth']}/>
              {(isPassword || props['passwordOnly'])
                && <Input
                  id="login-password"
                  name="password"
                  type="password"
                  label={yext.msg('Password')}
                  value={password}
                  onChange={newPassword => setPassword(newPassword)}
                  placeholder={yext.msg('Password')}
                  hideLabel={true}/>}
            </>}

          {isNonUniqueSaml
            && <Input
              id="login-account-id"
              type="text"
              label={yext.msg('Account ID')}
              value={accountId}
              onChange={newAccountId => setAccountId(newAccountId)}
              placeholder={yext.msg('Account ID')}
              hideLabel={true}/>}

          {!isIdp && getLoginFormButton()}
        </form>

        {(!isSso && !isPassword && !isIdp)
        && <>
          <Checkbox
            label={rememberMeLabel}
            name="rememberMe"
            value={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
            checked={rememberMe}
          />

          {forgotPasswordSignupSection}

          {renderEmployeeZendeskRedirectLogin(props['redirectUrl'])}

          {props['isYextDomain']
          && <>
            <div className="yext-login__divider">{yext.msg('or sign in with')}</div>
            <div className="yext-login__oauth-signup-options">
              {!props['passwordOnly']
                && <button
                  type="button"
                  id="sso-button"
                  className="users__login-oauth"
                  onClick={clickSsoButton}
                >
                  <SsoIcon />
                  <span>SSO</span>
                </button>}
              {!props['isMobileRedirect']
                && <OauthButtons
                  githubOAuthClientId={props['githubOAuthClientId']}
                  googleOAuthClientId={props['googleOAuthClientId']}
                  authenticityToken={props['authenticityToken']}
                  accountId={accountId}
                  redirectUrl={props['redirectUrl'] + window.location.hash}
                  setError={setError}/>}
            </div>
          </>
          }
        </>
        }

        {isIdp && <span id="sso-idp-message" className="message-text">
          {yext.msg('We can\'t log you in. Please reach out to your administrator')}
        </span>}

      </div>
      {props.isOsanoEnabled
        && <Button className="yext__toggle-osano-cookie-drawer" chromeless={true} onClick={openCookieDrawer}>
          {yext.msg('Cookie Preferences')}
        </Button>}
    </div>
  );
}

/**
 * Conditionally renders a button used by Yext employees who are directed to
 * Yext login from the Zendesk mobile app's login flow.
 * It allows them to login to Yext via employee oauth so that they don't have to
 * leave the mobile app's login flow.
 *
 * @param redirectUrl the redirect url given via query param 'c'
 * @returns {React.ReactElement}
 */
function renderEmployeeZendeskRedirectLogin(redirectUrl) {
  try {
    const redirectsToZendesk = redirectUrl && redirectUrl.startsWith('/idp/zendesk');
    if (!redirectsToZendesk) {
      return null;
    }
    return (
      <div className="center-align">
        <a href="https://yext.okta.com/app/zendesk/exk1hm8btz2I3OHC71d8/sso/saml">
          {yext.msg('I am an Agent')}
        </a>
      </div>
    );
  } catch (e) {
    logError(e);
  }
}

slowRegisterComponentForJsonData('PartitionLogin', PartitionLogin);
